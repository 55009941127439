/**
 * 回収管理ページ用のスクリプト
 */
if (is_route('bss_replacement_collect.index')) {
    // スクリプト読込
    require('../Base/replacement_collect_base.index');

    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        replacement_collect_base_event_set();
        replacement_collect_base_setting_event_set();
    });
}