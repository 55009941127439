import { setFileDownloadEvent } from "../../util/download";
import { setupFileUpload } from "../../util/upload";

/**
 * 注文台帳ページ用のスクリプト
 */
if (is_route('bss_order.index') || is_route('bss_order_recieve.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        bss_order_event_set();
        bss_order_setting_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.bss_order_event_set = function () {

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id'] );

    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id', 'type'],false,setAddEndMstSelect);
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-confirm-modal]', function () {
        // 編集時
        if ($(this).data('id')) {
            // 商品ごとの合計数を抽出
            let curProducts = {};
            $('#shipping_product-part .product-row').each(function () {
                // 配送先削除にチェックが入っている場合は次へ
                if (true == $(this).closest('.shipping-card').find('.shipping_delete input').prop('checked')){
                    return true;
                }
                // 商品削除にチェックが入っている場合は次へ
                if (true == $(this).find('.product_delete input').prop('checked')){
                    return true;
                }
                let productId = $(this).find('.product-select').val();
                let productAmount = parseInt($(this).find('.product_amount .number-input-value').val());
                if(productId in curProducts){
                    curProducts[productId] = curProducts[productId] + productAmount;
                }else{
                    curProducts[productId] = productAmount;
                }
            });

            // 編集開始時と比較して差分のproduct_idを抽出
            let difProductIds = [];
            $.each(iniProducts, function (id, amount) {
                if(amount != curProducts[id]){
                    difProductIds.push(id);
                }
                delete curProducts[id];
            });
            difProductIds = difProductIds.concat(Object.keys(curProducts));

            if(0 < difProductIds.length){
                _modal.create_with_data(this, 'save-confirm-modal', { 'product_ids': difProductIds });
                return false;
            }
        }

        _modal.submit(this, 'save-submit-modal', function(results) {
            $('[data-detail-modal=order-detail-modal][data-id=' + results.order.id + ']').trigger('click');
        }, function() {
            order_set_steper_error();
        });
    });
    
    // メール詳細
    $(document).on('click', '[data-mail-detail-modal]', function () {
        _modal.create(this, 'mail-detail-modal', ['order_shippint_id']);
    });
    
    // 登録通知メール編集
    $(document).on('click', '[data-mail-setting-modal]', function () {
        _modal.create(this, 'mail-setting-modal', ['order_shippint_id']);
    });

    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            $('[data-detail-modal=order-detail-modal][data-id=' + results.order.id + ']').trigger('click');
        }, function() {
            order_set_steper_error();
        });
    });
    
    // toアドレス追加
    $(document).on('click', '[data-append-to_emails]', function () {
        toIndex++;
        let emailInput = getInputEmail('order_recieve-mail-setting-modal-to_emails_' + toIndex + '_address', 'to_emails[' + toIndex + '][address]');
        $('#to_emails-wrapper').append(emailInput);
    });
    
    // ccアドレス追加
    $(document).on('click', '[data-append-cc_emails]', function () {
        ccIndex++;
        let emailInput = getInputEmail('order_recieve-mail-setting-modal-cc_emails_' + ccIndex + '_address', 'cc_emails[' + ccIndex + '][address]');
        $('#cc_emails-wrapper').append(emailInput);
    });
    
    // アドレス削除
    $(document).on('click', '[data-remove]', function () {
        $(this).closest('.email-address').remove();
    });
    
     // メール送信
     $(document).on('click', '[data-mail-send]', function () {
        _modal.submit(this, 'mail-send', function (results) {
        }, function (xhr, status, errorThrown) {
        });
    });
    
    function getInputEmail(id, name) {
        return $(`<div class="email-address">
                    <input type="email" id="${id}" class="multi-error" name="${name}" />
                    <span class="remove-button" data-remove>削除</span>
                    <div class="" id="${id}-errors">
                        <span class="invalid-feedback copy" role="alert"></span>
                    </div>
                </div>
                `);
    }

    /*---- アサイン処理 ----*/

    $(document).on('click', '[data-assign]', function () {
        let orderRecord = $(this).closest('tr.order-row');
        let action = $(this).data('action');
        let datas = {'id':$(this).data('id')};
        // 送信
        _ajax.post(action, datas, function (results) {
            // 表示レコード更新
            orderRecord.find('.recieve_user_name').text(results.order.recieve_user_name);
            orderRecord.find('.assign').removeClass('d-none');
            orderRecord.find('.unassign').removeClass('d-none');

            // メッセージ表示
            _complete.set(results);
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    $(document).on('click', '[data-unassign]', function () {
        let orderRecord = $(this).closest('tr.order-row');
        let action = $(this).data('action');
        let datas = {'id':$(this).data('id')};
        // 送信
        _ajax.post(action, datas, function (results) {
            // 表示レコード更新
            orderRecord.find('.recieve_user_name').text(results.order.recieve_user_name);
            orderRecord.find('.assign').removeClass('d-none');
            orderRecord.find('.unassign').addClass('d-none');

            // メッセージ表示
            _complete.set(results);
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- COTOS番号 ----*/
    // 入力画面表示
    $(document).on('click', '[data-cotos_no-modal]', function () {
        let $productRow = $(this).closest('.product-row');
        let $productCotosNo = $(this).closest('.product_cotos_no');

        _modal.create_with_data(this, 'cotos_no-modal',
            {
                'target_id': $productCotosNo.find('input').attr('id'),
                'product_id': $productRow.find('.product_name select option:selected').val(),
                'amount': $productRow.find('.product_amount .number-input-value').val(),
                'cotos_no': $productCotosNo.find('input').val()
            }
        );
    });

    // 入力値をhiddenに保存
    $(document).on('click', '[data-set-cotos_no]', function () {
        let targetId = $(this).data('target_id');
        let cotosNo = '';
        $(this).closest('.modal-content').find('.cotos_no input').each(function () {
            if('' != $(this).val()){
                cotosNo = cotosNo + $(this).val() + ',';
            }
        });
        cotosNo = cotosNo.slice(0, -1);

        $('#shipping_product-part #' + targetId).val(cotosNo);

        _modal.hide('#order-cotos_no-modal');
    });

    // 一括反映
    $(document).on('click', '[data-cotos_no-copy]', function () {
        let targetIndex = $(this).data('index');
        let cotosInputs = $('#order-cotos_no-modal .cotos_no input');
        let targetValue = $(this).closest('tr').find('.cotos_no input').val();
        cotosInputs.each(function (index, elem) {
            if(index <= targetIndex){
                return true; // continue
            }
            $(elem).val(targetValue);
        });
    });

    /*---- 配送単位受注処理 ----*/
    $(document).on('click', '[data-shipping-recieve-modal]', function () {
        _modal.submit(this, 'shipping-recieve-modal',function (results) {
            if(results.is_file_ok==false)
            {
                return
            }
            // 登録通知書PDFダウロード
            _ajax.blob(results.download_contract,{ 'pdf_name':results.pdf_name,'end_holding_code':results.end_holding_code}, function (blob) {
                let link = document.createElement("a")
                link.href = URL.createObjectURL(blob)
                link.setAttribute("download", results.pdf_name)
                link.click()
                link = null
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            })
        })
    });

    /*---- 商品詳細単位受注処理 ----*/

    $(document).on('click', '[data-product-recieve]', function (e) {
        e.stopPropagation();
        let productRecord = $(this).closest('tr.product-row');
        let shippingRecord = productRecord.prevAll('.shipping-row:first');
        let shippingProductId = $(this).data('bss_shipping_product_id');
        var action = $(this).data('action');
        var datas = {
            'bss_shipping_product_id': shippingProductId,
        };
        if($(this).data('temporary')){
            datas.temporary = $(this).data('temporary');
        }
        // 送信
        _ajax.post(action, datas, function (results) {
            if (true == results.is_shipping_setting){
                // 配送受注入力画面表示
                shippingRecord.data('shipping_product_id', results.shipping_product_id);
                shippingRecord.data('temporary', results.temporary);
                _modal.create(shippingRecord, 'shipping-setting-modal', ['order_shipping_id', 'shipping_product_id', 'temporary']);
            }else{
                // コンテンツの読込
                load_contents();

                // メッセージ表示
                _complete.set(results);
                // 注意メッセージをセットする
                _complete.warningSet(results);
            }

        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

     /*---- 登録通知書ダウンロードボタン処理 ----*/
    $(document).on('click', 'a.alc-register-pdf-download', function (e) {
        const contract_file_path=$(this).data('contract_file_path')
        // console.log('contract_file_path: ', contract_file_path)
        if(!contract_file_path.includes('/'))
        {
            return false
        }
        const pdfname = contract_file_path.substring(contract_file_path.lastIndexOf('/') + 1)
        _ajax.blob($(this).data('action'), {
            contract_file_path
        }, function (result) {
                let link = document.createElement("a")
                link.href = URL.createObjectURL(result)
                link.setAttribute("download", pdfname)
                link.click()
                link = null
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        })
        return false
    });

    $(document).on('click', '[data-product-unrecieve]', function (e) {
        e.stopPropagation();
        let productRecord = $(this).closest('tr.product-row');
        let shippingRecord = productRecord.prevAll('.shipping-row:first');
        let orderRecord = shippingRecord.prevAll('.order-row:first');
        let action = $(this).data('action');
        let datas = {
            'bss_shipping_product_id': $(this).data('bss_shipping_product_id'),
        };
        // 送信
        _ajax.post(action, datas, function (results) {
            // コンテンツの読込
            load_contents();

            // メッセージ表示
            _complete.set(results);
            // 注意メッセージをセットする
            _complete.warningSet(results);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-delete-confirm-modal]', function () {
        if (null != $(this).data('id')) {
            _modal.create_with_data(this, 'delete-confirm-modal', {'id': $(this).data('id')});
        }
    });

    /*---- 削除処理 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            _modal.hide('#order-detail-modal');
        });
    });

    /*---- CSVダウンロード時 ----*/

    // 発注情報ダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    // 受注済情報ダウンロード画面表示
    $(document).on('click', '[data-order-download_receive_search-modal]', function () {
        _modal.create(this, 'order-download_receive_search-modal', []);
    });

    // 受注済情報ダウンロード
    const getActionAndDataCallbackForSearchModal = function (eventElem) {
        const modalForm = $(eventElem).parents('.modal-content').find('form');
        const action = extend_url_query(modalForm.attr('action'));
        // 出荷日時情報
        const modalData = modalForm.serializeArray();
        // 検索フォームを配列化
        const searchData = $('#search-form').serializeArray();
    
        const data = [...modalData, ...searchData];

        return [action, data];
    };
    const failCallback = function (xhr, status, errorThrown) {
        if (xhr.status == 400) {
            toastr.warning('指定された受注済情報はありません。');
        } else {
            _error.omitted(xhr, status, errorThrown);
        }
    }
    setFileDownloadEvent('[data-order-shipping_recieve_download-modal]', getActionAndDataCallbackForSearchModal, failCallback);
    
    // 詳細画面の受注情報ダウンロード
    const getActionAndDataCallbackForDetail = function (eventElem) {
        const action = $(eventElem).data('action');
        const data = $(eventElem).closest('.shipping_recieve_download').serializeArray();
        return [action, data];
    }
    setFileDownloadEvent('#detail-action_recieve_download', getActionAndDataCallbackForDetail, failCallback);

    // OSOファイルアップロード
    setupFileUpload([{ fileName: 'oso_file'}]);
    $(document).on('click', '[data-upload-oso-modal]', function () {
        _modal.create(this, 'upload-oso-modal', [], false);
    });

    // 申込書ファイルアップロード
    setupFileUpload([
        { fileName: 'apply_csv_file' },
        { fileName: 'apply_excel_file'}
    ]);
    $(document).on('click', '[data-upload-apply-modal]', function () {
        _modal.create(this, 'upload-apply-modal', [], false);
    });

    // 新規配送先グループ会社モーダルの確認ボタン
    $(document).on('click', '[data-save-holding-modal]', function () {
        const modalId = '#order-add-end-mst-modal'
        const formId =  '#order-add-end-holding-modal-form'
        _error.reset()
        _ajax.submit(formId, function (results) {
            _complete.set(results)
            _complete.warningSet(results)
            _modal.hide(modalId)

            // テンプレートのエンドグループ会社セレクタを更新
            const $template_shipping_card = $('#shipping_product-part #template-shipping-card')
            const $card = $($template_shipping_card.html())
            $card.find('select').each(function() {
                if(this.id==='order-setting-modal-shippings_0_end_holding_id')
                {
                    $(this).empty()
                    $(results.options).each((i, e) => $(this).append('<option value="' + e.id + '" code="' + e.code + '">' + e.text + '</option>'))
                }
            })
            $template_shipping_card.html('<div class="shipping-card row mt-3">'+$card.html()+'</div>')

            // 配送先グループ会社のセレクタ(複数)を最新のエンドグループ会社オプションで更新
            $('select.select2-add-end-holding').each(function() {
                // 全GP会社のセレクタの選択を新規追加のエンドグループ会社で設定
                $(this).empty()
                results.options.forEach(e => $(this).append('<option value="' + e.id + '" code="' + e.code + '">' + e.text + '</option>'))
                $(this).val(results.end_holding.id).trigger('change')
            })
        }, function(xhr, status, errorThrown){
            _error.set("#order-add-end-holding-modal", xhr, status, errorThrown);
            order_set_steper_error();
        })
    })

    // 新規配送先会社モーダルの確認ボタン
    $(document).on('click', '[data-save-company-modal]', function () {
        const modalId = '#order-add-end-mst-modal';
        const formId = '#order-add-end-company-modal-form'
        _error.reset()

        _ajax.submit(formId, function (results) {
            _complete.set(results)
            _complete.warningSet(results)
            _modal.hide(modalId)

            // 配送先グループ会社のセレクタ(複数)を最新のエンドグループ会社オプションで更新
            $('select.select2-add-end-company').each(function() {
                // 発火のセレクタなら、新規追加のエンドグループ会社で設定
                if($('#input-add-end-company-from-select-id').val()!=this.id && $(this).attr('end_holding_id')!= results.end_company.end_holding_id)
                {
                    return
                }
                const setVal = $('#input-add-end-company-from-select-id').val()==this.id?results.end_company.id:$(this).val();
                // 更新実行
                $(this).empty()
                results.options.forEach(e => $(this).append('<option value="' + e.id + '">' + e.text + '</option>'))
                $(this).val(setVal).trigger('change')
            })
        }, function(xhr, status, errorThrown){
            _error.set("#order-add-end-company-modal", xhr, status, errorThrown);
            order_set_steper_error();
        })
    })


    // 新規配送先営業所モーダルの確認ボタン
    $(document).on('click', '[data-save-section-modal]', function () {
        const modalId = '#order-add-end-mst-modal';
        const formId = '#order-add-end-section-modal-form'
        _error.reset()

        _ajax.submit(formId, function (results) {
            _complete.set(results)
            _complete.warningSet(results)
            _modal.hide(modalId)

            // 配送先グループ会社のセレクタ(複数)を最新のエンドグループ会社オプションで更新
            $('select.select2-add-end-section').each(function() {
                if($('#input-add-end-section-from-select-id').val()!=this.id && $(this).attr('end_company_id')!= results.end_section.end_company_id )
                {
                    return
                }
                const setVal = $('#input-add-end-section-from-select-id').val()==this.id?results.end_section.id:$(this).val()
                // 更新実行
                $(this).empty()
                results.options.forEach(e => $(this).append('<option value="' + e.id + '">' + e.text + '</option>'))
                $(this).val(setVal).trigger('change')
            })
        }, function(xhr, status, errorThrown){
            _error.set("#order-add-end-section-modal", xhr, status, errorThrown);
            order_set_steper_error();
        })
    })


    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });

    /*---- 印刷ボタン押下時 ----*/
    // BSSforALC登録通知書印刷時
    $(document).on('click', '#search-form-action_print', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#order-list').serializeArray();

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 商品表示時 ----*/

    // 商品表示時
    $(document).on('click', '[data-product-detail-modal]', function () {
        _modal.create(this, 'product-detail-modal', ['product_id']);
    });

    /*---- 受注済情報ダウンロード検索範囲モーダルの「範囲指定」か「全部」のラジオボタン選択変更する時 ----*/
    $(document).on('change', '#order-download_receive_search-modal input[type=radio][name=is_by_date_range]', function () {
        const dateRangeDiv = document.querySelector('#order-download_receive_search-modal div[class*=date-range-container]')
        const isByDateRange = this.value === 'true'
        dateRangeDiv.style.pointerEvents = isByDateRange ? 'auto' : 'none'
        dateRangeDiv.style.opacity = isByDateRange ? '1' : '0.4'
    });

    /*---- 仮申込変更時 ----*/

    // 仮申込変更時
    $(document).on('click', '[data-change_temporary_application-modal]', function () {
        _modal.create(this, 'change_temporary_application-modal', ['id'] );
    });
}
/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.bss_order_setting_event_set = function() {

    /*---- ステップボタンクリック時（新規登録時） ----*/

    // 前へ
    $(document).on('click', '[data-prev-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let prevPart = $(this).data('prev-step');
        let prevPartTrigger = create_id(prevPart + '-trigger');
        let prevPartSubmit = create_id(prevPart + '-submit');
        $(currentPartSubmit).addClass('display-none');
        $(prevPartSubmit).removeClass('display-none');
        bs_step_trigger(prevPartTrigger);
    });

    // 次へ
    $(document).on('click', '[data-next-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let thisPart = $(this).data('confirm-step');
        let nextPart = $(this).data('next-step');
        let nextPartTrigger = create_id(nextPart + '-trigger');
        let nextPartSubmit = create_id(nextPart + '-submit');
        // ModalとPartセレクターの指定
        let modalId = '#order-setting-modal';
        let formId = '#order-setting-modal-form';
        let action = $(formId).data('confirm-action');
        // 確認対象のパートをパラメータにセット
        $('#order-setting-modal-confirm_step').val(thisPart);
        // 送信前にエラーをリセット
        _error.reset()
        order_set_steper_error(false);
        // 確認用のpost送信
        _ajax.confirm_submit(formId, action, function (results) {
            let canNext = true;
            if('shipping_product' == thisPart){

                if(results.datas.obj_emails_for_update_view) { // バックエンド変換したメールの入力値をフロンドエンドに更新
                    for (const prop in results.datas.obj_emails_for_update_view) {
                        results.datas.obj_emails_for_update_view[prop].forEach(
                            function (mail_inputed_item) {
                                $('.shipping-card[data-count="' + mail_inputed_item.shipping_data_count + '"]')
                                    .find('input[type="text"][name="shippings[' + mail_inputed_item.shipping_data_count + '][' + prop + ']"]')
                                    .val(mail_inputed_item.value_conversed)
                            }
                        )
                    }
                }

                if(results.datas.add_products){
                    // 指定商品を追加する
                    $(results.datas.add_products).each(function (idx, data) {
                        let $shipppingCard = $('.shipping-card[data-count="' + data.shipping_data_count + '"]');
                        addProduct($shipppingCard.find('button[name="add_product"]')[0], function () {
                            if (idx < results.datas.add_products.length - 1) {
                                return;
                            }
                            let group_datas= _(results.datas.add_products).groupBy('shipping_data_count').value();
                            console.log('group_datas: ', group_datas);
                            for (const [objkey, arrShipingProduts] of Object.entries(group_datas)) {
                                for (let i = 0; i < arrShipingProduts.length; i++) {
                                    const curData = arrShipingProduts[i];
                                    let $shipppingCard = $('.shipping-card[data-count="' + curData.shipping_data_count + '"]');
                                    const $productRow = $shipppingCard.find('.product-row');
                                    const idx_from_last = arrShipingProduts.length - i;
                                    const $productRowCur = $productRow.eq(-idx_from_last);

                                    $productRowCur.find('.product-select').val(curData.product_id).change();
                                    $productRowCur.find('.product_amount input').val(curData.amount).change();
                                }
                            }
                        });
                    });
                    canNext = false;
                    alert('セットに対応する月額商品が含まれていなかったため追加しました。商品情報をご確認ください。');
                }
            }
            if(canNext){
                order_setConfirm(thisPart);

                $(currentPartSubmit).addClass('display-none');
                $(nextPartSubmit).removeClass('display-none');
                bs_step_trigger(nextPartTrigger);
            }
        }, function (xhr, status, errorThrown) {
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
            // ステップエラーを表示
            order_set_steper_error();
        });
    });

    // 一時保存
    $(document).on('click', '[data-temporary-save]', function () {
        let modalId = '#order-setting-modal';
        let formId = '#order-setting-modal-form';
        let action = $(formId).data('temporary-action');
        let thisPart = $(this).data('temporary-step');
        // 確認対象のパートをパラメータにセット
        $('#order-setting-modal-confirm_step').val(thisPart);

        // 送信前にエラーをリセット
        _error.reset()
        order_set_steper_error(false);
        // 確認用のpost送信
        _ajax.confirm_submit(formId, action, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // コンテンツの読込
            load_contents();
        }, function (xhr, status, errorThrown) {
            // エラーをセットする
            _error.set(modalId, xhr, status, errorThrown);
            // ステップエラーを表示
            order_set_steper_error();
        });
    });

    /*---- 配送情報の追加ボタンを押下(入力項目を追加する) ----*/

    $(document).on('click', '#shipping_product-part button[name="add_shipping"]', function () {
        let addInputVars =  $($('#shipping_product-part #template-shipping-card').html()).serializeArray().length;
        if(!checkMaxInputVars(addInputVars)){
            alert('これ以上追加できません。');
            return false;
        }

        var count = $('#shipping_product-part .shipping-card').length;
        var card = $($('#shipping_product-part #template-shipping-card').html()).eq(0).find('.shipping-card');

        // 番号付与
        card.attr('data-count', count);
        card.find('.card-header span').text(count + 1);
        $('.shipping-count').text(count + 1);

        // 追加要素の初期化
        card.find('input').each(function () {
            // チェックボックスの場合はvalueを残す
            if (!'checkbox' == $(this).attr('type'))
            {
                $(this).val('');
            }

            if ($(this).hasClass('number-input-value'))
            {
                // name書き換え
                let name = $(this).attr('name');
                name = name.replace(/shippings\[\d\]/, 'shippings[' + count + ']');
                $(this).attr('name', name);
            } else if ($(this).hasClass('number-input'))
            {
                var id = $(this).attr('id');
                var newId = id.replace(/shippings_0/g , 'shippings_' + count);
                var error = card.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            } else
            {
                var id = $(this).attr('id');
                var newId = id.replace(/shippings_0/g , 'shippings_' + count);
                var label = card.find('label[for="' + id + '"]');
                var error = card.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                label.attr('for', newId);

                // name書き換え
                let name = $(this).attr('name');
                name = name.replace(/shippings\[\d\]/, 'shippings[' + count + ']');
                $(this).attr('name', name);

                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            }
        });

        card.find('select').each(function() {
            var id = $(this).attr('id');
            var newId = id.replace(/shippings_0/g , 'shippings_' + count);
            var label = card.find('label[for="' + id + '"]');
            var error = card.find('#' + id + '-errors');
            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // name書き換え
            let name = $(this).attr('name');
            name = name.replace(/shippings\[\d\]/, 'shippings[' + count + ']');
            $(this).attr('name', name);

            // error初期化
            if (error.length > 0)
            {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        card.find('.products-error').each(function() {
            var id = $(this).attr('id');
            var newId = id.replace(/shippings_0/g , 'shippings_' + count);
            var label = card.find('label[for="' + id + '"]');
            var error = card.find('#' + id + '-errors');

            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        card.find('table').each(function() {
            var id = $(this).attr('id');
            var newId = id.replace(/shippings_0/g , 'shippings_' + count);
            var label = card.find('label[for="' + id + '"]');
            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);
        });

        // data-zipcode書き換え
        let zipcodeBtn = card.find('[data-zipcode-button]');
        let zipcodeBtnData = zipcodeBtn.attr('data-zipcode-button');
        zipcodeBtnData = zipcodeBtnData.replace(/shippings_0/, 'shippings_' + count + '');
        zipcodeBtn.attr('data-zipcode-button', zipcodeBtnData);
        card.find('[data-zipcode]').each(function () {
            let zipcodeData = $(this).attr('data-zipcode');
            zipcodeData = zipcodeData.replace(/shippings_0/, 'shippings_' + count + '');
            $(this).attr('data-zipcode', zipcodeData);
        });

        // 必要な情報を最初の配送先からコピー
        let firstCard = $('#shipping_product-part .shipping-card:first');
        // DSJPメールアドレス
        card.find('input[name*="dsjp_emails"]').val(firstCard.find('input[name*="dsjp_emails"]').val());
        // 販社メールアドレス
        card.find('input[name*="ricoh_emails"]').val(firstCard.find('input[name*="ricoh_emails"]').val());

        // 最後に追加
        $('#shipping_product-part #shipping-cards').append(card);

        initialize();

        // 配送先の選択（グループ会社、会社、営業所）の設定
        setAddEndMstSelect();

        // For SSS-212 発注登録画面改修 ・発注登録画面の基本情報に「エンドユーザーグループ会社」入力項目追加 ・発注登録画面の「配送先グループ会社」項目を非表示
        $('#order-setting-modal-shippings_' + count + '_end_holding_id').val($('#order-setting-modal-end_holding_id').val()).trigger('change')

    });

    /*---- 商品の追加ボタンを押下(入力項目を追加する) ----*/

    $(document).on('click', '#shipping_product-part button[name="add_product"]', function () {
        // #template-product-rowに含まれるPOSTパラメータの数（初期セットが選択されている場合、商品が自動追加されるため2倍して計算）
        let addInputVars = 5 * 2;
        if(!checkMaxInputVars(addInputVars)){
            alert('これ以上追加できません。');
            return false;
        }

        addProduct(this);
    });


    /*---- 商品のセレクト変更時 ----*/
    $(document).on('change', '#shipping_product-part select.product-select', function () {
        order_setProduct(this, { 'id': $(this).val() });
    });

    /*---- 商品の数量変更時 ----*/
    $(document).on('change', '#shipping_product-part .product_amount input.number-input', function () {
        // 数量よりCOTOS番号が多くなったら末尾を削除
        let $cotosNo = $(this).closest('.product-row').find('.product_cotos_no input');
        let cotosArray = $cotosNo.val().split(',');
        let difLength = cotosArray.length - $(this).val();
        if (difLength > 0){
            cotosArray.splice(-1, difLength);
            $cotosNo.val(cotosArray.join(','));
        }

        order_calcAmountPrice(this);
        order_calcTotals(this);
    });

    /*---- 削除チェック変更時 ----*/
    $(document).on('change', '#shipping_product-part .product_delete input', function () {
        order_calcTotals(this);
    });

    /*---- 単価変更時 ----*/
    $(document).on('change', '#shipping_product-part .product_sell_price input[type="number"]', function () {
        let $parent = $(this).closest('.product_sell_price');
        $parent.find('span.number').text($(this).val());
        $parent.find('input[type="hidden"]').val($(this).val());
        order_calcAmountPrice(this);
        order_calcTotals(this);
    });

    /*---- 出荷予定日変更時 ----*/
    $(document).on('change', '[data-get-answered_arrived_at]', function () {
        // 日付として有効でない場合は抜ける
        let date = new Date($(this).val());
        if(isNaN(date.getDate())){
            return false;
        }

        let action = $(this).data('action');
        let datas = {
            'id': $(this).data('id'),
            'estimated_shipping_at': $(this).val()
        };
        // 送信
        _ajax.post(action, datas, function (results) {
            // 回答納期自動入力
            $('#order_recieve-shipping-setting-modal .answered_arrived_at input').val(results.answered_arrived_at);
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- エンド営業所のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.end_section-select', function () {
        if($(this).val()<0)
        {
            // 新規営業所追加のタイミングなら処理しない
            return;
        }
        if($(this).val()){
            setSectionInfo(this, {'id': $(this).val()});
        }else{
            setSectionInfo(this);
        }
    });

    // 営業所情報を反映する
    function setSectionInfo(elm, datas = {}, callback = null) {
        let shippingCard = $(elm).parents('.shipping-card');
        let $endUserName = shippingCard.find('input[name*="end_user_name"]');
        let $endUserStaffName = shippingCard.find('input[name*="end_user_staff_name"]');
        let $zipcode = shippingCard.find('input.zipcode');
        let $prefecture = shippingCard.find('.address-inputs select.prefecture');
        let $municipality = shippingCard.find('.address-inputs input.municipality');
        let $address = shippingCard.find('.address-inputs input.address');
        let $phone_number = shippingCard.find('input[name*="end_user_phone_number"]');
        let $emails = shippingCard.find('input[name*="[emails]"]');

        if(Object.keys(datas).length){
            // POSTして値を設定
            let action = $(elm).data('address-action');
            _ajax.post(action, datas, function (results) {
                $endUserName.val(results.end_company.name + ' ' + results.model.name);
                $endUserStaffName.val(results.model.charge_name);
                $zipcode.val(results.model.zipcode);
                $prefecture.val(results.model.prefecture);
                $municipality.val(results.model.municipality);
                $address.val(results.model.address);
                $phone_number.val(results.model.phone_number);
                $emails.val(results.model.emails);
                if (callback != null) {
                    callback(elm);
                }
            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }else{
            // 空にする
            $endUserStaffName.val('');
            $endUserName.val('');
            $zipcode.val('');
            $prefecture.val('');
            $municipality.val('');
            $address.val('');
            $phone_number.val('');
        }
    }

    // 商品を追加する
    function addProduct(elem, callback = null) {
        var shippingCount = $(elem).parents('.shipping-card').attr('data-count');

        var table = $(elem).parents('.products').find('table');
        var count = table.find('.product-row').length;
        var tr = $($('#shipping_product-part #template-product-row').html());

        // 追加要素の初期化
        tr.find('input').each(function() {
            // チェックボックスの場合はvalueを残す
            if (!'checkbox' == $(this).attr('type')){
                $(this).val('');
            }

            if ($(this).hasClass('number-input-value')){
                // name書き換え
                let name = $(this).attr('name');
                name = name.replace(/shippings\[\d\]/, 'shippings[' + shippingCount + ']');
                name = name.replace(/\[products\]\[\d\]/, '[products][' + count + ']');
                $(this).attr('name', name);
            } else if ($(this).hasClass('number-input')){
                var id = $(this).attr('id');
                var newId = id.replace(/shippings_0/g , 'shippings_' + shippingCount);
                newId = newId.replace(/products_0/g , 'products_' + count);
                var error = tr.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            } else{
                var id = $(this).attr('id');
                var newId = id.replace(/shippings_0/g , 'shippings_' + shippingCount);
                newId = newId.replace(/products_0/g , 'products_' + count);
                var label = tr.find('label[for="' + id + '"]');
                var error = tr.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                label.attr('for', newId);

                // name書き換え
                let name = $(this).attr('name');
                if (name){
                    name = name.replace(/shippings\[\d\]/, 'shippings[' + shippingCount + ']');
                    name = name.replace(/\[products\]\[\d\]/, '[products][' + count + ']');
                    $(this).attr('name', name);
                }

                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            }
        });

        tr.find('select').each(function() {
            var id = $(this).attr('id');
            var newId = id.replace(/shippings_0/g , 'shippings_' + shippingCount);
            newId = newId.replace(/products_0/g , 'products_' + count);
            var label = tr.find('label[for="' + id + '"]');
            var error = tr.find('#' + id + '-errors');
            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // name書き換え
            let name = $(this).attr('name');
            name = name.replace(/shippings\[\d\]/, 'shippings[' + shippingCount + ']');
            name = name.replace(/\[products\]\[\d\]/, '[products][' + count + ']');
            $(this).attr('name', name);

            // error初期化
            if (error.length > 0){
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        // 商品選択項目呼び出し
        let productSelect = tr.find('.product-select');
        let action = $(elem).data('action');
        let subjectId = $('#order-setting-modal select[name="subject_id"]').val();
        if(subjectId){
            let datas = {
                conditions: {
                    is_visible: 1,
                    subject_id: subjectId,
                    is_bss: 1
                },
                select_text:'distributor_product_name'
            }
            
            // 送信
            _ajax.post(action, datas, function (results) {
                productSelect.empty();
                productSelect.select2({
                    width: "100%",
                    data: results.options
                });
                if (callback != null) {
                    callback();
                }
            }, function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            });
        }

        // 最後に追加
        table.find('.tr-target').before(tr);

        initialize();
    }

    // ユーザー管理番号で検索 (For SSS-212 発注登録画面改修 ユーザー管理番号入力して「検索」ボタン押下したらエンドユーザーグループ会社を自動選択する)
    $(document).on('click', 'button.end-hoding-code-search-button', function () {
        $("#order-setting-modal-end_holding_id option").each(function () {
            if ($(this).attr('code') === document.getElementById('shippings_end_holding_code_search').value) {
                $('#order-setting-modal-end_holding_id').val($(this).val()).trigger('change')
                return false
            }
        });
    });

    // 上位階層の情報コピー(company用) ボタン
    $(document).on('click', '#order-add-end-mst-modal #copy-holding-info', function () {
        let hidden_values = $('#holding-info').find(".hidden-holding-value");
        hidden_values.each(function(index, element){
            $("#" + $(element).attr('id').replace('hidden-holding-', '')).val($(element).text());
        });
    });

    // 上位階層の情報コピー(section用) ボタン
    $(document).on('click', '#order-add-end-mst-modal #copy-company-info', function () {
        let hidden_values = $('#company-info').find(".hidden-company-value");
        hidden_values.each(function(index, element){
            $("#" + $(element).attr('id').replace('hidden-company-', '')).val($(element).text());
        });
    });

    function checkMaxInputVars(addInputVars) {
        let maxInputVars = parseInt($("meta[name='max_input_vars']").attr('content'));
        let formInputVars = $('#order-setting-modal form').serializeArray().length;
        if (maxInputVars < formInputVars + addInputVars){
            return false;
        }
        return true;
    }
}

/**
 * ステッパーへのエラー表示処理
 * @param {*} showError
 */
window.order_set_steper_error = function (showError = true) {
    if (showError) {
        $('#base-part-trigger').toggleClass('error', ($('#base-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#shipping_product-part-trigger').toggleClass('error', ($('#shipping_product-part').find('.invalid-feedback:not(.copy)').length > 0));
    } else {
        $('#base-part-trigger').removeClass('error');
        $('#shipping_product-part-trigger').removeClass('error');
    }
}

/**
 * 商品レコードに情報を反映
 * @param {*} elm
 * @param {*} datas
 * @param {*} callback
 */
window.order_setProduct = function (elm, datas = {}, callback = null) {
    let productRow = $(elm).closest('tr.product-row');
    if (productRow.length > 0)
    {
        // 初期化
        productRow.find('td.product_amount input[type="text"]').attr('max', 0);
        productRow.find('td.product_amount input[type="text"]').val(0);
        productRow.find('td.product_amount input[type="hidden"]').val(0);
        productRow.find('td.product_name input[name*="[product_name]"]').val();
        productRow.find('td.product_code span.text').empty();
        productRow.find('td.product_code input').val('');
        productRow.find('td.product_cotos_no div').addClass('d-none');
        productRow.find('td.product_cotos_no input').val('');
        productRow.find('td.product_sell_price span.text').empty();
        productRow.find('td.product_sell_price span.number').empty();
        productRow.find('td.product_sell_price input[type="number"]').val('');
        productRow.find('td.product_sell_price input[type="hidden"]').val('');
        productRow.find('td.product_amount_price span.text').empty();
        productRow.find('td.product_amount_price span.number').empty();
        productRow.find('td.product_amount_price input').val('');

        let action = $(elm).data('action');
        _ajax.post(action, datas, function (results) {
            if (!results.model) {
                return false;
            }
            productRow.find('td.product_name input[name*="[product_name]"]').val(results.model.distributor_product_name);
            productRow.find('td.product_code span.text').text(results.model.distributor_product_code);
            productRow.find('td.product_code input').val(results.model.distributor_product_code);
            if(1 == results.model.running_type
            || 2 == results.model.running_type){
                productRow.find('td.product_cotos_no div').removeClass('d-none');
            }
            productRow.find('td.product_amount input[type="text"]').attr('max', null);
            productRow.find('td.product_sell_price span.text').text(results.sell_price.toLocaleString());
            productRow.find('td.product_sell_price span.number').text(results.sell_price);
            productRow.find('td.product_sell_price input[type="number"]').val(results.sell_price);
            productRow.find('td.product_sell_price input[type="number"]').attr('max', null);
            productRow.find('td.product_sell_price input[type="hidden"]').val(results.sell_price);
            order_calcAmountPrice(elm);
            order_calcTotals(elm);
            if (callback != null) {
                callback(elm);
            }
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    }
}

/**
 * 金額を計算して反映
 * @param {*} elm
 */
window.order_calcAmountPrice = function (elm) {
    let productRow = $(elm).closest('tr.product-row');
    let productPrice = Number(productRow.find('td.product_sell_price span.number').text());
    let productAmount = Number(productRow.find('td.product_amount .number-input').val());

    // 金額
    productRow.find('td.product_amount_price span.text').text((productPrice * productAmount).toLocaleString());
    productRow.find('td.product_amount_price span.number').text(productPrice * productAmount);
}

/**
 * 小計、消費税、合計を計算して反映
 */
window.order_calcTotals = function (elm) {
    // 小計
    let productTable = $(elm).closest('table');
    let subtotal = 0;
    productTable.find('tr.product-row').each(function () {
        if (true == $(this).find('.product_delete input').prop('checked')){
            return true;
        }
        subtotal += Number($(this).find('td.product_amount_price span.number').text());
    });
    productTable.find('td.product_subtotal span.text').text(subtotal.toLocaleString());
    productTable.find('td.product_subtotal span.number').text(subtotal);

    // 消費税
    let tax_rate = Number($('#tax_rate').text());
    let tax = Math.floor(tax_rate / 100 * subtotal);
    productTable.find('td.product_tax span.text').text(tax.toLocaleString());
    productTable.find('td.product_tax span.number').text(tax);

    // 合計
    productTable.find('td.product_total span.text').text((tax + subtotal).toLocaleString());
    productTable.find('td.product_total span.number').text(tax + subtotal);

}

/**
 * 確認画面に値を設定
 * @param {*} thisPart
 */
window.order_setConfirm = function (thisPart) {
    if ('base' == thisPart){
        $('#base-part input[type="text"]').each(function() {
            let confirmId = '#' + $(this).attr('id') + '-confirm';
            $(confirmId).text($(this).val());
        });
        $('#base-part input[type="radio"]:checked').each(function() {
            let confirmId = '#' + $(this).attr('id') + '-confirm';
            confirmId = confirmId.replace(/_\d/g, '');
            let radioId = $(this).attr('id');
            let selectedValue = document.getElementById(radioId).value;
            let confirmText;
            if (confirmId == '#order-setting-modal-apply_type-confirm' && selectedValue == 0) {
                confirmText = '新規';
            } else if(confirmId == '#order-setting-modal-apply_type-confirm' && selectedValue == 1) {
                confirmText = '追加';
            }
            $(confirmId).text(confirmText);
        });
        $('#base-part select').each(function () {
            let confirmId = '#' + $(this).attr('id') + '-confirm';
            let confirmText = $(this).find('option:selected').text();
            $(confirmId).text(confirmText);
        });
    } else if ('shipping_product' == thisPart){
        $('#confirm-part #shipping-cards-confirm').empty();

        // 配送情報をコピーして確認画面に追加
        $('#shipping_product-part .shipping-card').each(function () {
            let confirmCard = $($('#confirm-part #template-shipping-card-confirm').html());

            $(this).find('input[type="text"]').each(function() {
                if (!$(this).attr('id').match(/_products/)){
                    let confirmId = $(this).attr('id') + '-confirm';
                    confirmId = confirmId.replace(/shippings_\d_/g , 'shippings_');
                    confirmCard.find('#' + confirmId).text($(this).val());
                }
            });

            $(this).find('input[type="radio"]:checked').each(function() {
                if (!$(this).attr('id').match(/_products/)){
                    let confirmId = $(this).attr('id') + '-confirm';
                    confirmId = confirmId.replace(/shippings_\d_/g , 'shippings_');
                    confirmId = confirmId.replace(/_\d-confirm/g , '-confirm');
                    confirmCard.find('#' + confirmId).text($(this).parent().find('span').text());
                }
            });

            $(this).find('input[type="checkbox"]:checked').each(function() {
                if (!$(this).attr('id').match(/_products/)){
                    let confirmId = $(this).attr('id') + '-confirm';
                    confirmId = confirmId.replace(/shippings_\d_/g , 'shippings_');
                    confirmCard.find('#' + confirmId).text($(this).val());
                }
            });

            $(this).find('select').each(function() {
                if (!$(this).attr('id').match(/_products/)){
                    let confirmId = $(this).attr('id') + '-confirm';
                    confirmId = confirmId.replace(/shippings_\d_/g, 'shippings_');
                    confirmCard.find('#' + confirmId).text($(this).find('option:selected').text());
                }
            });

            // 商品情報をコピーして確認画面に追加
            $(this).find('table').each(function () {
                $(this).find('.product-row').each(function () {
                    let confirmProductRow = $($('#confirm-part #template-product-row-confirm').html());

                    confirmProductRow.find('.product_order_status').text($(this).find('.product_order_status input[type="radio"]:checked').parent().find('span').text());
                    confirmProductRow.find('.product_name').text($(this).find('.product_name select option:selected').text());
                    confirmProductRow.find('.product_code').text($(this).find('.product_code').text());
                    let cotosNo = $(this).find('.product_cotos_no input').val();
                    if ('' != cotosNo){
                        confirmProductRow.find('.product_cotos_no').text('有');
                    }
                    confirmProductRow.find('.product_amount').text($(this).find('.product_amount input.number-input').val());
                    confirmProductRow.find('.product_sell_price').text(parseInt($(this).find('.product_sell_price span.number').text()).toLocaleString());
                    confirmProductRow.find('.product_amount_price').text(parseInt($(this).find('.product_amount_price span.number').text()).toLocaleString());
                    confirmProductRow.find('.product_delete').text($(this).find('.product_delete input:checked').val());

                    confirmCard.find('table tr.tr-target').before(confirmProductRow);
                });

                confirmCard.find('table').find('.product_subtotal').text($(this).find('.product_subtotal span.text').text());
                confirmCard.find('table').find('.product_tax').text($(this).find('.product_tax span.text').text());
                confirmCard.find('table').find('.product_total').text($(this).find('.product_total span.text').text());
            });

            $('#confirm-part #shipping-cards-confirm').append(confirmCard);
        });

    }
}

window.setAddEndMstSelect = function() {
    $('select.select2-add-end-holding,select.select2-add-end-company,select.select2-add-end-section').each(function () {
        $(this).select2({
            // placeholder: "選択して下さい",
            tags: true,
            // minimumResultsForSearch: 1,
            width: "100%",
            createTag: function (params) {
                // console.log('createTag params: ', params)
                let term = $.trim(params.term);
                if (term === '') {
                    return null;
                }
                return {
                    id: -999,
                    text: term,
                    isNewFlag: true
                };
            },
        }).on('select2:select', function (e) {
            // console.log('select2-selecting: e',e)
            // 選択した要素に"isNewFlag"があるか, 新しく追加した要素だけ実行する処理
            if (e.params.data.isNewFlag) {
                // 削除してモーダルを開く
                $(this).find('[value="' + e.params.data.id + '"]').remove()

                $(this).val('').trigger('change')

                const modalId = '#order-add-end-mst-modal'

                // モーダルのコンテンツを空に
                $(modalId + ' .modal-content').empty()
                // 新規配送先（GP会社,会社、営業所）画面取得のAJAX送信 (現在のセレクタのIDと入力した内容を渡す)
                const data = {'select-id': this.id, 'new-value': e.params.data.text}
                if (this.id.endsWith('end_company_id')) {
                    data.end_holding_id = $(this).attr('end_holding_id')
                    if (data.end_holding_id == "") {
                        return
                    }
                } else if (this.id.endsWith('end_section_id')) {
                    data.end_company_id = $(this).attr('end_company_id')
                    if (data.end_company_id == "") {
                        return
                    }
                }
                // addone 新規配送先（GP会社,会社、営業所）画面取得のurl
                _ajax.post($(this).data('addone'), data, function (results) {
                    // 画面ロード
                    $(modalId + ' .modal-content').html(results)
                    // 再初期化
                    initialize()
                    // 画面表示
                    _modal.show(modalId)
                }, function (xhr, status, errorThrown) {
                    _error.omitted(xhr, status, errorThrown);
                }, 'html')
            }
        })
        // .on('select2:open', function(e) {
        //     // console.log('this.id: ', this.id)
        //     $('input.select2-search__field').prop('placeholder', '検索する文字列を入力してください');
        // })
    })
}
