/**
 * ページ読み込み時のイベント設定
 */
window.replacement_shipping_base_event_set = function() {

    // 日付ごとの発送品一覧表示
    $(document).on('click', '[data-date_group-modal]', function () {
        _modal.create(this, 'date_group-modal', ['to_estimated_shipping_at'], true);
    });

    // 設定表示
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['holding_code', 'company_code', 'shipping_section_code', 'to_estimated_shipping_at'], true);
    });

    const convertSlipcode = function(element){
        element.value = element.value.replace(/[a-zA-Z]/g, '');
    }
    $(document).on('focusout', '#replacement_shipping-setting-modal-form input[name="return_slip_code"], #replacement_shipping-setting-modal-form input[name="to_slip_code"]', function () {
        convertSlipcode(this);
    });
    $(document).on('keydown', '#replacement_shipping-setting-modal-form input[name="return_slip_code"], #replacement_shipping-setting-modal-form input[name="to_slip_code"]', function (e) {
        if (e.keyCode === 188) { // カンマ入力時
            convertSlipcode(this);
        }
    });

    $(document).on('focusout', '#replacement_shipping-setting-modal-form input.to-sn-AC-015[type="text"]', function () {
        this.value = this.value.replace(/,/g, '');
    });

    $('#replacement_shipping-setting-modal').on('shown.bs.modal', function () {
        $('#replacement_shipping-setting-modal-to_slip_code').focus();
    });

    $(document).on('keydown', '#replacement_shipping-setting-modal-form input[type="text"]', (e) => {
        if ([13, 38, 40].includes(e.keyCode)) {
            e.preventDefault();
            const currentIndex = parseInt($(e.target).attr('tabindex'));

            const lastIndex = $('#replacement_shipping-setting-modal-form input[type="text"]').length;
            let newIndex;
            if (e.keyCode === 40 || e.keyCode === 13) {
                newIndex = currentIndex === lastIndex ? 1 : currentIndex + 1;
            } else {
                newIndex = currentIndex === 1 ? lastIndex : currentIndex - 1;
            }
            $(`[tabindex=${newIndex}]`).focus();
        }
    });

// 交換品発送リストダウンロード
    $(document).on('click', '[data-download_shipping_list]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let data = $('#search-form').serializeArray();

        // Blobでダウンロード
        _ajax.blob(action, data, function (blob, status, xhr) {
            let fileName = decodeURIComponent(xhr.getResponseHeader('filename'));
            // aタグを経由してダウンロード
            var a = document.createElement("a");
            var blobUrl = window.URL.createObjectURL(new Blob([blob], {
                type: blob.type
            }));
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = blobUrl;
            a.download = fileName;
            a.click();
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // 詳細画面：交換品発送リストダウンロード
    $(document).on('click', '[data-download_shipping_list_date_group]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let data = $('#search-form').serializeArray();
        // 発送予定日を追加
        data.push({ name: 'to_estimated_shipping_at', value: $(this).data('to_estimated_shipping_at') });
        
        // Blobでダウンロード
        _ajax.blob(action, data, function (blob, status, xhr) {
            let fileName = decodeURIComponent(xhr.getResponseHeader('filename'));
            // aタグを経由してダウンロード
            var a = document.createElement("a");
            var blobUrl = window.URL.createObjectURL(new Blob([blob], {
                type: blob.type
            }));
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = blobUrl;
            a.download = fileName;
            a.click();
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });


    // ヤマトCSVダウンロード
    $(document).on('click', '[data-yamato_csv]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // データを取得
        let datas = {
            to_estimated_shipping_at: $(this).data('to_estimated_shipping_at')
        };

        // BlobでCSVダウンロード
        _ajax.blob(action, datas, function (blob, status, xhr) {
            let fileName = decodeURIComponent(xhr.getResponseHeader('filename'));
            // aタグを経由してダウンロード
            var a = document.createElement("a");
            var blobUrl = window.URL.createObjectURL(new Blob([blob], {
                type: blob.type
            }));
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = blobUrl;
            a.download = fileName;
            a.click();
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // 送付状PDFダウンロード
    $(document).on('click', '[data-cover_letter_pdf]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        let datas = {
            holding_code: $(this).data('holding_code'),
            company_code: $(this).data('company_code'),
            shipping_section_code: $(this).data('shipping_section_code'),
            to_estimated_shipping_at: $(this).data('to_estimated_shipping_at')
        };

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // 発送指示書PDFダウンロード
    $(document).on('click', '[data-instruction_pdf]', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        let datas = {
            to_estimated_shipping_at: $(this).data('to_estimated_shipping_at')
        };

        // BlobでPDFを別ウィンドウで表示
        _ajax.blob(action, datas, function (blob) {
            window.open(window.URL.createObjectURL(blob), '_blank');
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });
}
/**
 * 設定モーダル表示時のイベント設定
 * @uses ダッシュボードからモーダル表示する際にも呼び出される
 */
window.replacement_shipping_base_setting_event_set = function () {

    // シリアル重複チェック
    const serialDuplicateCheck = () => {
        $('.serial-duplicate-check-error').remove();
        let inputs = $('.serial-duplicate-check');
        let serials = {};
        inputs.each(function() {
            let serial = $(this).val();
            if(serial.length == 0) //空文字は無視
                return;
            if (serial in serials) {
                serials[serial]["count"]++;
                serials[serial]["inputs"].push(this);
            } else {
                serials[serial] = {"count": 1, "inputs": [this]};
            }
        });
    
        let checkOK = true;
        for (let serial in serials) {
            if (serials[serial]["count"] > 1) {
                checkOK = false;
                serials[serial]["inputs"].forEach(function(input) {
                    let selector = "#" + $(input).attr('id') + "-errors";
                    let msg = $(selector).find('.copy').clone();
                    msg.removeClass('copy');
                    msg.addClass('serial-duplicate-check-error')
                    msg.text("交換先シリアルの値が重複しています。");
                    $(selector).append(msg);
                });
            }
        }
        return checkOK;
    }

    // 設定保存
    $(document).on('click', '[data-save-modal]', function () {
        if(false == serialDuplicateCheck()) {
            return;
        }

        // エラーリセット
        _error.reset()
        
        // ボタンに応じてto_statusをデータに含める
        let toStatus = $(this).data('to_status');
        $('#replacement_shipping-setting-modal-form [name="to_status"]').val(toStatus);

        let datas = $('#replacement_shipping-setting-modal-form').serialize();
        let modalId = create_id($(this).data('mail-setting-modal'));
        let action = $(this).data('action');
        // 送信
        _ajax.post(action, datas, function (results) {
            // html, jsonの可能性があるため判定
            let isJson = true;
            try {
                var json = JSON.parse(results);
            } catch (error) {
                isJson = false;
            }

            if (isJson) {
                if(json.messages){
                    // メッセージを表示
                    _complete.set(json);
                }
                if(json.warnings){
                    // 注意メッセージを表示
                    _complete.warningSet(json);
                }
                if (!(json.to_status == 0 && json.messages && json.messages.length > 0 && json.messages[0].includes('保存しました'))) {
                    load_contents(function () {
                        $('[data-date_group-modal=replacement_shipping-date_group-modal][data-to_estimated_shipping_at=' + json.to_estimated_shipping_at + ']').trigger('click');
                    });
                }
                return;
            } else{
                // モーダルのコンテンツを空にする
                $(modalId + ' .modal-content').empty();
                // モーダルにコンテンツを追加する
                $(modalId + ' .modal-content').html(results);
                initialize();
                _modal.show(modalId);
            }
        }, function (xhr, status, errorThrown) {
           // エラーをセットする
            _error.set('#replacement_shipping-setting-modal', xhr, status, errorThrown, true, false);
        }, 'html');
    });

    // シリアル重複チェック
    $(document).on('blur', '.serial-duplicate-check', function () {
        serialDuplicateCheck();
    });

    // メール送信
    $(document).on('click', '[data-mail-send]', function () {
        // エラーリセット
        _error.reset()
        
        // 出荷情報
        let settingFormDatas = $('#replacement_shipping-setting-modal-form').serialize();
        // メール情報
        let mailSettingFormDatas = $('#mail-setting-modal-form').serialize();

        let datas = settingFormDatas + '&' + mailSettingFormDatas;
        let action = $(this).data('action');
        // 送信
        _ajax.post(action, datas, function (results) {
            if(results.messages){
                // メッセージを表示
                _complete.set(results);
            }
            if(results.warnings){
                // 注意メッセージを表示
                _complete.warningSet(results);
            }
            load_contents(function () {
                $('[data-date_group-modal=replacement_shipping-date_group-modal][data-to_estimated_shipping_at=' + results.to_estimated_shipping_at + ']').trigger('click');
            });
            return;
        }, function (xhr, status, errorThrown) {
           // エラーをセットする
            _error.set('#mail-setting-modal', xhr, status, errorThrown, true, false);
        });
    });

    // メール詳細
    $(document).on('click', '[data-mail-detail-modal]', function (event) {
        event.stopPropagation();
        _modal.create(this, 'mail-detail-modal', ['to_estimated_shipping_at', 'holding_code', 'company_code', 'section_code', 'shipping_section_code', 'mail_key', 'mail_index']);
    });

    // メール編集画面用スクリプト読込
    require('../../util/mail');
}