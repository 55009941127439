/**
 * グループ会社ページ用のスクリプト
 */
if (is_route('defect_management.index')) {
    // スクリプト読込
    require('./Base/defect_management_base.index');

    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        defect_management_base_event_set();
        defect_management_base_setting_event_set();
    });
}
