/**
 * ページ読み込み時のイベント設定
 */
window.defect_management_base_event_set = function() {
    // モーダルID
    const modelId = '#defect_management-setting-modal';

    // 詳細表示時
    $(document).on('click', '#defect_management_list', function () {
        _modal.create(this, 'detail-modal', ['id']);
        return false;
    });

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'setting-modal', ['id', 'type'], false, function() {
            let is_product = null;
            let is_defect = null;
            let product_label = null;
            let defect_label = null;
            is_product = $(modelId + '-product_class option:selected').val();
            is_defect = $(modelId + '-defect_status option:selected').val();
            product_label = document.getElementsByClassName("badge bg-danger badge-required  product_class fadeout ");
            defect_label = document.getElementsByClassName("badge bg-danger badge-required  defect_status fadeout ");
            if(is_product != null) {
                if(is_product != 1) {
                    $(product_label).css('display', 'none');
                } else{
                    $(product_label).css('display', 'inline-block');
                }
            }
            if(is_defect != null) {
                if(is_defect != 2) {
                    $(defect_label).css('display', 'none');
                } else{
                    $(defect_label).css('display', 'inline-block');
                }
            }
        });
    });

    //発送先変更用
    $(document).on('click', 'input#is_change_shipping_to', function() {
        if($('input#is_change_shipping_to').prop('checked')) {
            $('.change_shipping_to').removeClass('change_shipping_to_hidden');
            $('input#is_change_shipping_to').val(1);
        } else {
            $('.change_shipping_to').addClass('change_shipping_to_hidden');
            $('input#is_change_shipping_to').val(0);
        }
    });

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        let datas = $(modelId + '-form').serialize();
        let action = $(this).data('action');
        // 送信
        _ajax.post(action, datas, function (results) {
            // メッセージ表示
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modelId);
            // コンテンツの読込
            load_contents(function () {
                $('[data-detail-modal=defect_management-detail-modal][data-id=' + results.model.id + ']').trigger('click');
            });
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    });

    // CSVダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });
    // ヤマト用CSVダウンロード時
    $(document).on('click', '#yamato-csv-download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // IDの取得
        let tokenObj = $('#search-form').serializeArray().find(obj => obj.name === "_token");
        let datas = [];
        datas.push(tokenObj, {name:'id', value:$(this).data('id')});
        // ポスト送信を実行
        post(action, datas);
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });
}

/**
 * 設定モーダル表示時のイベント設定
 */
window.defect_management_base_setting_event_set = function () {
    // モーダルID
    const modelId = '#defect_management-setting-modal';

    /*---- ステップボタンクリック時（新規登録時） ----*/

    // 前へ
    $(document).on('click', '[data-prev-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let prevPart = $(this).data('prev-step');
        let prevPartTrigger = create_id(prevPart + '-trigger');
        let prevPartSubmit = create_id(prevPart + '-submit');
        $(currentPartSubmit).addClass('display-none');
        $(prevPartSubmit).removeClass('display-none');
        bs_step_trigger(prevPartTrigger);
        if (prevPart === 'maker_support-part') {
            $('.btn-cover_letter_pdf').show();
        } else {
            $('.btn-cover_letter_pdf').hide();
        }
    });

    // 次へ
    $(document).on('click', '[data-next-step]', function () {
        // Partセレクターの指定
        let currentPartSubmit = $(this).parents('.modal-footer');
        let nextPart = $(this).data('next-step');
        let nextPartTrigger = create_id(nextPart + '-trigger');
        let nextPartSubmit = create_id(nextPart + '-submit');
        // ModalとPartセレクターの指定
        let formId = modelId + '-form';
        let action = $(formId).data('confirm-action');
        // 確認対象のパートをパラメータにセット
        $(modelId + '-confirm_step').val($(this).data('confirm-step'));
        // 送信前にエラーをリセット
        _error.reset()
        defect_management_base_set_stepper_error(false);
        // 確認用のpost送信
        _ajax.confirm_submit(formId, action, function (results) {
            $(currentPartSubmit).addClass('display-none');
            $(nextPartSubmit).removeClass('display-none');
            bs_step_trigger(nextPartTrigger);
            if (nextPart === 'maker_support-part') {
                $('.btn-cover_letter_pdf').show();
            } else {
                $('.btn-cover_letter_pdf').hide();
            }
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.set(modelId, xhr, status, errorThrown);
            // ステップエラーを表示
            defect_management_base_set_stepper_error();
        });
    });

    // ステッパーボタン押下時
    $(document).on('click', '#support_desk-part-trigger', function() {
        $('.btn-cover_letter_pdf').hide();
    });
    $(document).on('click', '#maker_support-part-trigger', function() {
        $('.btn-cover_letter_pdf').show();
    });
    $(document).on('click', '#repair_estimate-part-trigger', function() {
        $('.btn-cover_letter_pdf').hide();
    });
    $(document).on('click', '#repair_request-part-trigger', function() {
        $('.btn-cover_letter_pdf').hide();
    });

    // 商品分類の変更時
    $(document).on('change', modelId + '-product_class', function() {
        is_product = $(modelId + '-product_class option:selected').val();
        product_label = document.getElementsByClassName("badge bg-danger badge-required  product_class fadeout ");
        if(is_product != 1) {
            $(product_label).css('display', 'none');
        } else{
            $(product_label).css('display', 'inline-block');
        }
    });

    // 不具合管理ステータスの変更時
    $(document).on('change', modelId + '-defect_status', function() {
        is_defect = $(modelId + '-defect_status option:selected').val();
        defect_label = document.getElementsByClassName("badge bg-danger badge-required  defect_status fadeout ");

        const element = document.getElementById("defect_management-setting-modal-shipped_at");
        const is_shipment_completed = element && element.value.trim() !== "";
        const is_disable_shipment_input = is_defect != 2 || is_shipment_completed;
        $('.input_about_shipping').each(function () {
            $(this).prop('disabled', is_disable_shipment_input);
            let parentWithHidden = $(this).closest(':has(input[type="hidden"][name="' + $(this).attr('name') + '"])');
            if (is_disable_shipment_input) {
                if (parentWithHidden.length > 0) {
                    parentWithHidden.find('input[type="hidden"][name="' + $(this).attr('name') + '"]').val($(this).val());
                } else {
                    $(this).parent().append('<input type="hidden" name="' + $(this).attr('name') + '" value="' + $(this).val() + '">');
                }
            } else {
                if (parentWithHidden.length > 0) {
                    parentWithHidden.find('input[type="hidden"][name="' + $(this).attr('name') + '"]').remove();
                }
            }
        });
        if(is_disable_shipment_input) { // 不具合管理ステータスは「返却待ち」以外または 発送した場合、「出荷」ボタンと出荷関連の入力使用、変更不可(重複出荷を防ぐ)
           $(defect_label).css('display', 'none');
           $('.defect_management-setting-modal-shipping').prop('disabled', true);
        } else{// 不具合管理ステータスは「返却待ち」かつ 未発送の場合、「出荷」ボタンと出荷関連の入力を活性化,
           $(defect_label).css('display', 'inline-block');
           $('.defect_management-setting-modal-shipping').prop('disabled', false);
        }
    });

    // グループ会社または返却予定シリアルの変更時
    $(document).on('change', modelId + '-end_holding_id, ' + modelId + '-expected_serial', function() {
        let datas = [];
        let holdingId = $(modelId + '-end_holding_id').val();
        let serial = $(modelId + '-expected_serial').val();
        let action = $(this).data('action');
        datas.push({ 'name' : 'end_holding_id', 'value' : holdingId});
        datas.push({ 'name' : 'serial', 'value' : serial});
        // 初期化
        $(modelId + '-company_name').val('');
        $(modelId + '-section_name').val('');
        $(modelId + '-zipcode').val('');
        $(modelId + '-phone_number').val('');
        $(modelId + '-prefecture').val('');
        $(modelId + '-municipality').val('');
        $(modelId + '-address').val('');
        $(modelId + "-product_class").val('').change();
        if (holdingId != '' && serial != '') {
            // 送信
            _ajax.post(action, datas, function (results) {
                if (results.length === 0) {
                    $('.icon-alert-danger.badge.badge-danger').show();
                } else {
                    if (((typeof results === 'object' && !Array.isArray(results))) && (results.contract_id === null || !results.contract_end_holding_check) ) {
                        alert('シリアル「'+serial+'」が有効契約に紐づいていません、ご確認お願いいたします。');
                        $(modelId +'-end_holding_id').val('').trigger('change');
                        $(modelId +'-expected_serial').val('');
                        return;
                    }
                    $(modelId + '-company_name').val(results.company_name);
                    $(modelId + '-section_name').val(results.section_name);
                    $(modelId + '-zipcode').val(results.zipcode);
                    $(modelId + '-phone_number').val(results.phone_number);
                    $(modelId + '-prefecture').val(results.prefecture);
                    $(modelId + '-municipality').val(results.municipality);
                    $(modelId + '-address').val(results.address);
    
                    const modelInfo = results.model_information;
                    var optionValue = $(modelId + "-product_class option:contains('" + modelInfo + "')").val();
                    $(modelId + "-product_class").val(optionValue).change();

                    $('.icon-alert-danger.badge.badge-danger').hide();
                }

            }, function(xhr, status, errorThrown){
                _error.omitted(xhr, status, errorThrown);
            });
        }
    });

    // 送付状PDFダウンロード
    $(document).on('click', '[data-cover_letter_pdf]', function () {
        // 設定保存アクションの取得
        const saveAction = $(this).data('save_action');
        const saveData = $(modelId + '-form').serializeArray();

        // PDF出力アクションの取得
        const pdfAction = $(this).data('pdf_action');
        const pdfData = {
            id: $(this).data('id'),
            is_change_shipping_to: $('#is_change_shipping_to').prop('checked')
        };

        // 送付状に画面入力値を反映するための保存処理
        _ajax.post(saveAction, saveData, function () {

            // BlobでPDFを別ウィンドウで表示
            _ajax.blob(pdfAction, pdfData, function (blob) {
                window.open(window.URL.createObjectURL(blob), '_blank');
            }, function (xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            });
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });

    });

}

/**
 * ステッパーへのエラー表示処理
 * @param {*} showError
 */
window.defect_management_base_set_stepper_error = function (showError = true) {
    if (showError) {
        $('#support_desk-part-trigger').toggleClass('error', ($('#support_desk-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#maker_support-part-trigger').toggleClass('error', ($('#maker_support-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#repair_estimate-part-trigger').toggleClass('error', ($('#repair_estimate-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#repair_request-part-trigger').toggleClass('error', ($('#repair_request-part').find('.invalid-feedback:not(.copy)').length > 0));
    } else {
        $('#support_desk-part-trigger').removeClass('error');
        $('#maker_support-part-trigger').removeClass('error');
        $('#repair_estimate-part-trigger').removeClass('error');
        $('#repair_request-part-trigger').removeClass('error');
    }
}