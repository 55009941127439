/**
 * 品目マスタページ用のスクリプト
 */
 if (is_route('product.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        product_event_set();
        product_setteing_event_set();
    });
}

/**
 * 品目マスタページ読み込み時のイベント設定
 */
window.product_event_set = function() {

    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.product_license_file_link', function (e) {
        // バブリングを禁止
        e.stopPropagation();
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['id']);
    });

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    // 追加ボタンを押下、まずシステムカテゴリの選択ダイアログを表示
    $(document).on('click', '[data-system_category-modal]', function () {
        _modal.create(this, 'system_category-modal', ['id']);
    });

    // システムカテゴリの選択ダイアログのシステムカテゴリを選択したら、選択したシステムカテゴリを追加して、品目新規画面を表示
    $(document).on('change', '#product-system_category-modal input[name="system_category"]', function () {
        _modal.create(this, 'setting-modal', ['id'], false, null, 'action', {
            system_category: $(this).val(),
        });
    });

    /*---- 設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            $('[data-detail-modal=product-detail-modal][data-id=' + results.product.id + ']').trigger('click');
        }, function() {
            product_set_steper_error();
        });
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });

    /*---- 削除確認の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-confirm-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            // ツールバーの更新が必要なのでリロードする
            location.reload();
        });
    });
}

/**
 * ステッパーへのエラー表示処理
 * @param {*} showError
 */
 window.product_set_steper_error = function (showError = true) {
    if (showError) {
        $('#base-part-trigger').toggleClass('error', ($('#base-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#product_product-part-trigger').toggleClass('error', ($('#product_product-part').find('.invalid-feedback:not(.copy)').length > 0));
        $('#confirm-part-trigger').toggleClass('error', ($('#product_product-part').find('.invalid-feedback:not(.copy)').length > 0));
    } else {
        $('#base-part-trigger').removeClass('error');
        $('#product_product-part-trigger').removeClass('error');
        $('#confirm-part-trigger').removeClass('error');
    }
}


/**
 * セット品目入力欄の表示
 * ダッシュボードからモーダル表示する際にも呼び出される
 */
 window.product_setteing_event_set = function() {

    /*---- 商品の追加ボタンを押下(入力項目を追加する) ----*/

    $(document).on('click', '#product_product-part button[name="add_product"]', function () {
        console.log('add_product');

        var table = $(this).parents('.products').find('table');
        var count = table.find('.product-row').length;
        var tr = $($('#product_product-part #template-product-row').html());

        // 追加要素の初期化
        tr.find('input').each(function() {
            // チェックボックスの場合はvalueを残す
            if (!'checkbox' == $(this).attr('type'))
            {
                $(this).val('');
            }

            if ($(this).hasClass('number-input-value'))
            {
                // name書き換え
                let name = $(this).attr('name');
                name = name.replace(/products\[\d\]/, 'products[' + count + ']');
                $(this).attr('name', name);
            } else if ($(this).hasClass('number-input'))
            {
                var id = $(this).attr('id');
                var newId = id.replace(/products_0/g , 'products_' + count);
                var error = tr.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            } else
            {
                var id = $(this).attr('id');
                var newId = id.replace(/products_0/g , 'products_' + count);
                var label = tr.find('label[for="' + id + '"]');
                var error = tr.find('#' + id + '-errors');

                // ID書き換え
                $(this).attr('id', newId);
                label.attr('for', newId);

                // name書き換え
                let name = $(this).attr('name');
                name = name.replace(/products\[\d\]/, 'products[' + count + ']');
                $(this).attr('name', name);

                // error初期化
                if (error.length > 0) {
                    error.attr('id', newId + '-errors');
                    error.find('span').html('');
                }
            }
        });

        tr.find('select').each(function() {
            var id = $(this).attr('id');
            var newId = id.replace(/products_0/g , 'products_' + count);
            var label = tr.find('label[for="' + id + '"]');
            var error = tr.find('#' + id + '-errors');
            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // name書き換え
            let name = $(this).attr('name');
            name = name.replace(/products\[\d\]/, 'products[' + count + ']');
            $(this).attr('name', name);

            // error初期化
            if (error.length > 0)
            {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        // 最後に追加
        table.find('.tr-target').before(tr);

        initialize();

        // 15件になったら追加ボタン殺す
        if (count > 15) {
            $(this).prop('disabled', true);
        }
    });

    /*---- 商品のセレクト変更時 ----*/
    $(document).on('change', '#product_product-part select.product-select', function () {
        order_setProduct(this, { 'id': $(this).val() });
    });

    /*---- 商品の数量変更時 ----*/
    $(document).on('change', '#product_product-part .product_amount input.number-input', function () {
        order_calcAmountPrice(this);
        order_calcTotals(this);
    });

    /*---- 削除チェック変更時 ----*/
    $(document).on('change', '#product_product-part .product_delete input', function () {
        order_calcTotals(this);
    });

}



/**
* セット品目入力欄でセット品変更時に品番を表示する
* @param {*} elm
* @param {*} datas
* @param {*} callback
*/
 window.product_setProduct = function (elm, datas = {}, callback = null) {
    let productRow = $(elm).closest('tr.product-row');
    if (productRow.length > 0) {
        // 初期化
        productRow.find('td.product_amount input[type="text"]').attr('max', 0);
        productRow.find('td.product_amount input[type="text"]').val(0);
        productRow.find('td.product_amount input[type="hidden"]').val(0);
        productRow.find('td.product_name input[name*="[product_name]"]').val();
        productRow.find('td.product_code span.text').empty();
        productRow.find('td.product_code input').val('');
        productRow.find('td.product_sell_price span.text').empty();
        productRow.find('td.product_sell_price span.number').empty();
        productRow.find('td.product_amount_price span.text').empty();
        productRow.find('td.product_amount_price span.number').empty();
        productRow.find('td.product_amount_price input').val('');

        let action = $(elm).data('action');
        _ajax.post(action, datas, function (results) {
            productRow.find('td.product_name input[name*="[product_name]"]').val(results.model.name);
            productRow.find('td.product_code span.text').text(results.model.code);
            productRow.find('td.product_code input').val(results.model.code);
            productRow.find('td.product_amount input[type="text"]').attr('max', null);
            productRow.find('td.product_sell_price span.text').text(results.sell_price.toLocaleString());
            productRow.find('td.product_sell_price span.number').text(results.sell_price);
            productRow.find('td.product_sell_price input').val(results.sell_price);
            order_calcAmountPrice(elm);
            order_calcTotals(elm);
            if (callback != null) {
                callback(elm);
            }
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
    }
}

/*---- CSVダウンロード時 品目マスタ----*/

// CSVダウンロード時
$(document).on('click', '#search-form-action_download', function () {
    // アクションの取得
    let action = extend_url_query($(this).data('action'));
    // 検索フォームを配列化
    let datas = $('#csv-dl-list').serializeArray();
    // ポスト送信を実行
    post(action, datas);
});

/*---- CSVダウンロード時 セット品目マスタ----*/

// CSVダウンロード時
$(document).on('click', '#search-form-action_set_download', function () {
    // アクションの取得
    let action = extend_url_query($(this).data('action'));
    // 検索フォームを配列化
    let datas = $('#csv-dl-list').serializeArray();
    // ポスト送信を実行
    post(action, datas);
});